@import '../../styles/base';

/* Styles for the hamburger icon */
.hamburger-react {
  margin-left: auto;
  // the icon even though it's fully pushed to the left still has a bit of distance from the edge
  margin-right: toRem(-10);
  display: none;
  color: $text-color;
  z-index: 1000;

  @media (max-width: $desktop-extra-small) {
    display: unset;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $light-grey;
}

/* Sidebar wrapper styles */
.bm-menu-wrap {
  position: fixed;
  height: calc(100vh - $header-height) !important;
  width: 100% !important;
  top: calc($header-height);
  display: none;

  @media (max-width: $desktop-extra-small) {
    display: unset;
  }
}

/* General sidebar styles */
.bm-menu {
  background: $background-color;
  padding: toRem(16) $desktop-margin;

  @media (max-width: $mobile) {
    padding: toRem(16) $mobile-margin;
  }
}

.bm-item-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-weight: 400;
  font-size: toRem(22);
  line-height: toRem(24);
  color: $text-color;
  text-decoration: none;
  text-transform: capitalize;
  margin-top: toRem(32);

  &:hover {
    color: $primary-color;
  }
}

.bm-button {
  width: 100%;

  span {
    justify-content: center;
  }
}
