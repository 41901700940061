@import '../../styles/base';

.Separator {
  height: 0.0625rem;
  width: 100%;
  background-color: $dark-grey;
  margin: 2rem 0;

  @media (max-width: $mobile) {
    margin: 1.5rem 0;
  }
}
