@import '../../styles/base.scss';

.Button {
  @include font(
    $fontFamily: $font-default !important,
    $size: 1.125rem !important,
    $weight: 700 !important,
    $lh: normal !important
  );
  height: 3.4375rem !important;
  padding: 0 1rem !important;
  border: none !important;
  border-radius: $border-radius !important;

  @media (max-width: $tablet) {
    font-size: toRem(16) !important;
  }

  @media (max-width: $mobile) {
    font-size: toRem(14) !important;
  }

  &-text {
    display: inline-block; // or just inline
    max-width: 100%; // Make sure it respects its parent's width
    color: $text-color;
  }

  > span {
    @include flexBox($display: flex);
    align-items: center;
  }

  &:hover {
    background-color: $grey !important;
    color: $black !important;
  }

  &-primary {
    background-color: $primary-color !important;
    color: $black !important;

    &:hover {
      background-color: darken($primary-color-dark, 5%) !important;
    }
  }

  &-secondary {
    background-color: $white !important;

    .Button-text {
      color: $text-color;
    }

    &:hover {
      background-color: $grey !important;
    }

    &:active {
      background-color: $black !important;

      .Button-text,
      svg {
        color: $white;
      }

      svg,
      path {
        fill: white;
        stroke: white;
      }
    }
  }

  &-dark {
    background-color: $black !important;
    color: $white !important;

    .Button-text {
      color: $white;
    }

    &:hover {
      background-color: lighten($black, 15%) !important;
    }
  }

  &-transparent {
    background-color: transparent !important;
    color: $text-color !important;
    border: 0.125rem solid $text-color !important;
  }

  &-link {
    @include font(
      $size: 0.875rem !important,
      $weight: 400 !important,
      $color: $primary-color !important,
      $lh: 1.125rem !important
    );
    background: none !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
