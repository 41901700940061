@import '../../../../styles/base';

.UpcomingEventDetail {
  display: flex;
  align-items: center;
  color: $white;
  background-color: $violet;
  max-width: 900px;
  width: 100%;
  min-height: 54px;
  border-radius: 6px;
  padding: 0 2rem;
  margin-bottom: 0.6rem;

  &-live-event {
    background-color: $dark-violet;
  }

  &-date {
    font-size: 14px;
    font-weight: 700;
    min-width: max-content;
  }

  &-live-message {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0.7rem;
    min-width: 76px;

    img {
      margin: 0 0.4rem;
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    @include line-clamp(1);
  }

  &-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: center;
    padding-top: 0.5rem;

    &-title {
      max-width: 100%;
      text-align: center;
    }

    &-description {
      text-align: center;
    }
  }

  @media (max-width: $tablet-portrait) {
    border-radius: 0;
  }

  @media (max-width: $mobile) {
    padding: 0.5rem 1rem 0;
    text-align: center;

    &-live-message {
      display: none;
    }

    &-date {
      font-size: 12px;
      min-width: 72px;
    }

    &-title {
      font-size: 14px;
    }
  }
}
