@import '../../styles/base';
@import '../../styles/colors';
.VideoDetailTab {
  color: $text-color;
  &-button-container {
    border-bottom: 1px solid $grey40;
    padding-bottom: 0.5rem;
  }
  .Button-dark,
  .Button-secondary,
  .Button-transparent {
    height: 2.6rem !important;
    font-size: 1rem !important;
  }
  .Button-transparent {
    background-color: transparent !important;
    border: 0 !important;

    &:hover {
      background-color: #d2d2d8 !important;
      color: $text-color !important;
    }

    &:active {
      background-color: $white !important;
      color: $text-color !important;
    }
  }
  &-speakers-pill {
    padding-top: 1.2rem;
    padding-bottom: 0.5rem;
    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .image-container {
      min-width: 94px;
      height: 94px;
      background: lighten($black, 15%);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin: 0.5rem 1.5rem;
    }
    p {
      margin: 0 1.5rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &-resources-pill {
    width: 100%;
    overflow: hidden;
    .icon {
      width: 24px !important;
      margin-right: 0.6rem;
    }
    h6 {
      font-size: 18px;
      margin: 0;
      padding-top: 1.2rem;
      font-weight: 400;
    }
    p {
      font-size: 12px;
      margin: 0;
    }
    .icon-enabled {
      cursor: pointer;
    }
    .icon-disabled {
      cursor: not-allowed;
    }

    &-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-link {
      color: $white;
    }
    &-link:hover {
      cursor: pointer;
    }

    padding-bottom: 1rem;
    border-bottom: 1px solid $grey40;
  }
  padding-bottom: 2rem;
}
