@import '../../styles/base.scss';

.LoginPage {
  display: flex;
  justify-content: center;
  color: $text-color;

  h2 {
    padding-bottom: 40px;
  }
  .login-logo {
    width: 160px;
  }
  .login-image {
    background-image: url('../../assets/images/login.svg');
    background-repeat: round;
    width: 70%;
    height: 100vh;
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: auto;

    .toggle-remember {
      [class^='Toggle-labelContainer'],
      [class^='Toggle-toggleContainer'] {
        margin: 1px 0 0 0 !important;
      }
      [class^='Toggle-text'] {
        font-size: 14px;
        color: $light-grey;
      }
    }
    .forgot-password {
      a {
        color: $primary-color;
        text-decoration: none;
        font-size: 14px;
      }
    }
    label {
      font-size: 14px;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    label {
      margin-bottom: 0.5rem;
    }
    .input-error {
      border: 1px solid $red;
    }
    .error-message {
      padding-top: 5px;
      font-size: 13px;
      color: $red;
    }
  }

  @media (max-width: $desktop-small) {
    .form {
      width: unset;
      margin: auto;
      width: 28rem;
    }
    h2 {
      font-size: 30px;
      margin-top: 8rem;
    }
    .logo-desktop {
      display: none !important;
    }
    .login-image {
      display: none !important;
    }
  }

  @media (max-width: $mobile) {
    margin: 1rem;
    h2 {
      text-align: center;
      margin-left: 1rem;
    }
  }
}
