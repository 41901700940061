@import '../../styles/base';

.Search {
  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &-input {
    color: $text-color;
    background-color: $background-color;
    width: 100%;
    padding: 1rem 0.625rem;
    font-weight: 400;
    font-family: $font-default;
    font-size: 1rem;
    line-height: 1.25rem;
    border: 1px darken($background-color, 20%) solid;
    border-radius: $border-radius;

    &:focus {
      border: 1px $primary-color solid;
    }
  }

  &-icon {
    position: absolute;
    right: 0.75rem;
    color: $text-color;
    cursor: pointer;

    @media screen and (max-width: $desktop-extra-small) {
      bottom: 18px;
      right: 1rem;
    }

    svg {
      @include equal-dimensions(1.1rem);
    }
  }
}
