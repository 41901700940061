@import '../../styles/base.scss';
.VideoDetail {
  max-width: toRem(1224);
  margin: 2.5rem auto 4.4rem;
  .VideoDetail {
    padding: 0 3.5rem;
    @media (max-width: $tablet) {
      padding: 0 1rem;
      margin-top: 1.5rem;
    }
  }
  &-cuepoint {
    line-height: 20px;
    font-size: toRem(14);
    display: flex;
    &-time {
      color: $primary-color;
    }
    &-time:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    &-name {
      margin-left: 0.2rem;
      color: $text-color;
    }
  }
  &-info {
    &-title {
      @include font($fontFamily: $font-default, $size: 30px, $weight: 700, $color: $text-color);
      overflow: unset;
      line-height: 2rem;
      padding-bottom: 3px;
      @include line-clamp(3);
      margin: 0.75rem 0 0.375rem;
      @media (max-width: $tablet) {
        @include line-clamp(2);
        font-weight: 500;
      }
    }
    &-description {
      @include font(
        $fontFamily: $font-default,
        $size: 14px,
        $weight: 400,
        $lh: 1.375rem,
        $color: $text-color
      );
      @include line-clamp(3);
      margin: 0.75rem 0 0.375rem;
      @media (max-width: $tablet) {
        @include line-clamp(2);
        font-weight: 500;
      }
    }
    &-metadata {
      span {
        @include font($size: 14px, $weight: 400, $lh: 1.25rem, $color: $dark-grey);
        opacity: 0.66;
        padding-top: 1.2rem;
        &:after {
          position: relative;
          content: '|';
          margin: 0 0.25rem;
        }
        &:last-child:after {
          display: none;
        }
      }
    }
  }
}
