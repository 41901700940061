@import '../../styles/base.scss';

.SearchBar {
  height: 4.6rem;
  background: $background-color-dark;
  box-shadow: 0 0.0625rem 0 $background-color-dark;
  margin: 0 -#{$desktop-margin};

  &-search {
    width: 50%;

    .Search-input {
      font-family: $font-helvetica;
    }

    @media (max-width: $tablet) {
      width: 70% !important;
    }
    @media (max-width: $mobile) {
      width: 92% !important;
    }
  }
}
