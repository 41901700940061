@import '../../../../styles/base';

.UpcomingLiveEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-color;
  color: $text-color;
  padding: 2rem 0;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 2.5rem;
  }

  @media (max-width: $tablet-portrait) {
    background-color: $background-color;
  }
}
