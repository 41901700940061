@mixin font(
  $fontFamily: false,
  $size: false,
  $color: false,
  $weight: false,
  $style: false,
  $lh: false,
  $ls: false
) {
  @if $fontFamily {
    font-family: $fontFamily;
  }
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $style {
    font-style: $style;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $ls {
    letter-spacing: $ls;
  }
}

@mixin flexBox(
  $display: false,
  $flexDirection: false,
  $flexFlow: false,
  $alignItems: false,
  $justifyContent: false,
  $flexWrap: false,
  $flex: false
) {
  @if $display {
    display: $display;
  } @else {
    display: flex;
  }
  @if $flexDirection {
    flex-direction: $flexDirection;
  }
  @if $flexWrap {
    flex-wrap: $flexWrap;
  }
  @if $flexFlow {
    flex-flow: $flexFlow;
  }
  @if $alignItems {
    align-items: $alignItems;
  }
  @if $justifyContent {
    justify-content: $justifyContent;
  }
  @if $flex {
    flex: $flex;
  }
}

@mixin grid($rows: false, $columns: false, $gap: false, $rowGap: false, $columnGap: false) {
  display: grid;
  @if $gap {
    gap: $gap;
  }
  @if $rowGap {
    row-gap: $rowGap;
  }
  @if $columnGap {
    column-gap: $columnGap;
  }
  @if $rows {
    grid-template-rows: $rows;
  }
  @if $columns {
    grid-template-columns: $columns;
  }
}

@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}

@mixin background($imagePath: false, $position: center, $repeat: no-repeat, $size: contain) {
  @if $imagePath {
    background-image: url($imagePath);
  }
  @if $position {
    background-position: $position;
  }
  @if $repeat {
    background-repeat: $repeat;
  }
  @if $repeat {
    background-size: $size;
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin line-clamp($lines) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin width-classes {
  @for $i from 1 through 4 {
    $width: $i * 25;
    .w-#{$width} {
      width: #{$width}#{'%'};
    }
  }
}

@include width-classes;
$sizes: 12;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;

    .m-#{$i} {
      margin: $margin !important;
    }
    .ml-#{$i} {
      margin-left: $margin !important;
    }
    .mr-#{$i} {
      margin-right: $margin !important;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;

    .p-#{$i} {
      padding: $padding !important;
    }
    .pl-#{$i} {
      padding-left: $padding !important;
    }
    .pr-#{$i} {
      padding-right: $padding !important;
    }
    .pt-#{$i} {
      padding-top: $padding !important;
    }
    .pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}
@include padding-classes;
