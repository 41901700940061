@import '../../../styles/base.scss';

.Filter {
  position: relative;
  display: inline-block;
  margin-bottom: toRem(10);
  font-family: $font-helvetica;

  &:not(:last-child) {
    margin-right: toRem(10);
  }

  @media (max-width: $mobile) {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: toRem(10);
    }
  }

  &-chevron {
    margin-left: auto;

    &-hide {
      display: none;
    }
  }

  &-button {
    text-transform: capitalize;
    width: toRem(206);
    display: flex;
    align-items: center;
    background-color: $background-color;
    padding: toRem(10);
    font-size: toRem(16);
    font-family: $font-helvetica;
    color: $text-color;
    cursor: pointer;
    border: 1px solid darken($background-color, 20%);
    border-radius: toRem(4);

    @media (max-width: $mobile) {
      width: 100%;
    }

    &.open {
      color: $text-color;
    }
  }

  &-dropdown {
    position: absolute;
    width: 100%;
    color: $text-color;
    left: 0;
    z-index: 1;
    background-color: $background-color;
    border: 1px solid $dark-grey;
    border-radius: toRem(4);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: toRem(200);
    margin-top: toRem(4);
    overflow-y: auto;
    padding: toRem(8) 0;
  }

  &-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: toRem(8) toRem(4);

    span {
      flex: 1;
      font-size: toRem(14);
      @include line-clamp(2);
    }

    &:hover {
      background-color: $background-color-dark;
    }
  }

  .custom-checkbox {
    flex: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1px solid white;
    border-radius: 4px;
    background-color: $white;
    outline: none;
    position: relative;
    margin-right: toRem(8);
    border: 1px solid $black;
  }

  .custom-checkbox::before {
    /* Checkmark symbol styles */
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 10px;
    border: none;
  }

  .custom-checkbox:checked {
    /* Change background color when checkbox is checked */
    background-color: $primary-color;
    border: none;
  }

  .custom-checkbox:checked::before {
    /* Adjust checkmark color when checkbox is checked */
    border: 2px solid white;
    border-top: none;
    border-left: none;
  }
}
