@import '../../styles/base.scss';

.Dropdown-menu {
  width: 12.875rem;
  &-button {
    @include font(
      $fontFamily: $font-default !important,
      $size: 1rem !important,
      $weight: 400 !important,
      $lh: 1.25rem !important,
      $color: $text-color !important
    );
    @include flexBox($display: flex !important, $alignItems: center);
    width: 100%;
    height: 3.125rem !important;
    background-color: $background-color !important;
    border: 0.0625rem solid $text-color !important;
    border-radius: 0.25rem !important;
    padding: 0.625rem 1rem !important;
    [class^='Button-icon'] {
      margin-left: auto;
      > svg {
        @include equal-dimensions(1.5rem);
      }
    }
  }
  &-item {
    width: 12.875rem;
    color: $text-color !important;
    background-color: $background-color !important;
    border: none;
    padding: 0.625rem;
    &:hover {
      background-color: $background-color-dark !important;
    }
  }
}

[class^='Menu-container'] {
  background-color: $background-color !important;
  border: 0.0625rem solid $dark-grey;
}
