@import '../../styles/base.scss';

.Language {
  height: 100%;
  justify-content: center;

  &-wrapper {
    height: 100% !important;
  }

  &-box {
    cursor: pointer;
    width: toRem(294);
    height: toRem(43);
    border-radius: $border-radius;
    background-color: $black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: toRem(12) toRem(20);
    color: $white;

    &-active {
      border: toRem(1) solid $white;
    }

    &:not(:last-child) {
      margin-bottom: toRem(10);
    }
  }

  &-radio {
    @include equal-dimensions(toRem(17));
    margin: 0;
    accent-color: $primary-color;
  }
}
