@import '../../styles/base.scss';

.MyProfile {
  &-content {
    &-ctas {
      margin: 2.5rem auto 1.25rem;
      .Button {
        @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
        @include font($size: 1rem !important, $lh: 1.25rem !important);
        height: 2.5rem !important;
        padding: 0.625rem 1.25rem !important;

        &:not(.Button-dark) {
          background-color: $background-color !important;
        }

        &:first-child {
          margin-right: 0.625rem;
        }
      }
    }
    &-filters {
      margin-bottom: 2.75rem;
    }
    .Loading-indicator {
      margin-top: 6rem;
    }
  }
  &-favorites {
    min-height: 400px;
  }
  &-no-favorites {
    &-title {
      @include font(
        $fontFamily: $font-default,
        $size: 1.5rem,
        $weight: 700,
        $lh: 1.75rem,
        $color: $text-color
      );
      margin: 4.625rem auto 1.25rem;
    }
    &-info {
      @include font(
        $fontFamily: $font-default,
        $size: 1.125rem,
        $weight: 400,
        $lh: 1.5rem,
        $color: $text-color
      );
      margin: 0 auto;
    }
    &-cta {
      margin-top: 5rem !important;
    }
  }
  &-no-watched-items {
    &-title {
      @include font(
        $fontFamily: $font-default,
        $size: 1.5rem,
        $weight: 700,
        $lh: 1.75rem,
        $color: $text-color
      );
      margin: 4.625rem auto 1.625rem;
    }
  }
}
