@import '../../../../styles/base.scss';

.HeroEvent {
  &-container {
    position: relative;
    color: $white;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    height: 500px;
    margin-bottom: 3rem;
    overflow: hidden;
    width: 100vw;
    margin-left: -2.125rem;

    @media (max-width: $desktop-extra-small) {
      height: 580px;
    }

    @media (max-width: $mobile) {
      margin-left: -1.25rem;
    }

    &-video-background {
      width: 100%;
      z-index: -1;
      background-color: $black;

      @media (max-width: $tablet) {
        height: fit-content;
      }

      .ReactPlayer-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        opacity: 0.4;

        @media (max-width: $tablet) {
          & > div {
            width: 140vw;
            left: -20vw;
          }
        }

        @media (max-width: $tablet-portrait) {
          width: 160vw;
          left: -20vw;
        }

        .ReactPlayer {
          & > div {
            max-width: none !important;
            @media (max-width: $tablet) {
              width: 140vw;
              left: -20vw;
            }

            @media (max-width: $tablet-portrait) {
              width: 160vw;
              left: -20vw;
            }

            @media (max-width: $mobile) {
              width: 220vw;
              left: -20vw;
            }

            @media (max-width: $mobile-small) {
              width: 280vw;
              left: -30vw;
            }

            @media (max-width: $mobile-extra-small) {
              width: 300vw;
              left: -30vw;
            }
          }
        }
      }

      @media (min-width: $mobile) and (max-width: $tablet) {
        margin-bottom: 2rem;
      }

      .Countdown-container {
        margin-right: 5rem;

        svg {
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.4));
        }
      }
    }

    .HeroEvent-buttons-container.desktop {
      margin-bottom: 1.5rem;
    }

    .HeroEvent-buttons-container.desktop,
    .Countdown-desktop {
      display: flex;
    }

    .HeroEvent-buttons-container.mobile,
    .Countdown-mobile {
      display: none !important;
    }

    @media (max-width: $mobile) {
      padding: 1rem 0.4rem 0;
      margin-bottom: 0;
    }

    @media (max-width: $mobile-extra-small) {
      padding-inline: 0 !important;
    }
  }

  &-inner-container {
    position: absolute;
    top: 0;
    z-index: 3;
    padding: $desktop-margin;
    display: flex;

    @media (max-width: $desktop-extra-small) {
      display: block;

      .Countdown-container {
        margin-right: 0;
      }
    }

    @media (max-width: $tablet) {
      max-width: 100%;
    }

    @media (max-width: $mobile) {
      padding: 1rem;
      padding-right: 1.4rem;
    }

    @media (max-width: $mobile-small) {
      .Countdown-container {
        scale: 0.9;
      }
    }

    @media (max-width: $mobile-extra-small) {
      .Countdown-container {
        scale: 0.8;
      }
    }
  }

  &-left-container {
    padding-right: 4rem;

    @media (max-width: $tablet) {
      padding-right: 0;
      margin-bottom: 2rem;
    }

    @media (max-width: $tablet-portrait) {
      margin-bottom: 0rem;
    }
  }

  &-text {
    &-eyebrow {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 22px;
      margin: 0;
      @include line-clamp(1);

      @media (max-width: $mobile) {
        font-weight: 500;
        margin-top: 1rem;
      }

      @media (max-width: $mobile-extra-small) {
        margin-top: 0.5rem;
      }
    }

    &-title {
      font-size: 3rem;
      font-weight: 700;
      line-height: 64px;
      margin: 1rem 0;
      @include line-clamp(2);

      @media (max-width: $tablet) {
        line-height: 3rem;
      }

      @media (max-width: $mobile) {
        font-size: 2.4rem;
        line-height: 3rem;
        margin-block: 0.8rem;
      }

      @media (max-width: $mobile-small) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }

    &-description {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 1rem;
      @include line-clamp(3);

      @media (max-width: $mobile-extra-small) {
        margin-bottom: 1.6rem;
      }
    }
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    button:nth-child(1) {
      margin: 1rem 1.3rem 0 0 !important;
    }

    button:nth-child(2) {
      margin: 1rem 0 0 !important;
    }

    @media (max-width: $mobile) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &-play-icon {
    cursor: pointer;
  }

  &-mute {
    cursor: pointer;
    font-size: toRem(32);
    position: absolute;
    bottom: toRem(8);
    right: toRem(32);
    opacity: 0.7;
    z-index: 3;

    @media (max-width: $desktop-extra-small) {
      bottom: unset;
      top: 0.5rem;
    }

    @media (max-width: $mobile) {
      width: 1.3rem;
      top: 0.9rem;
      right: 22px;
    }
  }

  &-player {
    width: 100%;
    margin: 0 auto;
    .ReactPlayer > div > div {
      position: relative;
      max-width: $desktop-extra-small;
      padding-top: 0 !important;
      margin: 0 auto;
      .video-js {
        position: relative !important;
        width: 100% !important;
        padding-top: 56.25%;
      }
      &.bc-player-wrapper {
        max-width: $desktop-medium;
        .video-js {
          width: calc(100% - toRem(360)) !important;
          @media (max-width: $tablet) {
            width: 100% !important;
          }
        }
        #bc-chat {
          margin-left: auto;
        }
      }
    }
  }
}
