$black: #000000;
$black-variation: #181818;
$erie-black: #1e1e1e;
$mine-shaft: #282828;
$dark-charcoal: #2e2e2e;
$grey40: #404040;
$grey200: #edeaef;
$dark-grey: #4d4d4d;
$silver: #a6a6a6;
$grey: #d2d2d7;
$light-grey: #f9f9f9;
$alabaster: #f7f7f7;
$white: #ffffff;
$neutral: #f4f7f8;
$dark-blue: #0066ab;
$blue: #40a9f0;
$purple: #8764f9;
$red: #f00;
$cyan: #00ddf4;
$dark-cyan: #00ddf4;
$violet: #7b27ff;
$dark-violet: #25006d;
$mine-shaft: #252525;
$gray24: #3d3d3d;

$primary-color: $cyan;
$primary-color-dark: $dark-cyan;
$secondary-color: $violet;
$secondary-color-dark: $dark-violet;
$background-color: $white;
$background-color-dark: $neutral;
$text-color: $black;
