@import '../../styles/base.scss';

.ContainerWithTitle {
  margin: 0 auto;
  color: $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &-container {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: $desktop-max-width;
    align-items: flex-start;
    justify-content: space-between;
    padding: toRem(86);

    @media (max-width: $tablet) {
      padding: toRem(34);
    }

    @media (max-width: $mobile) {
      padding: toRem(20);
    }
  }
}
