@import '../../styles/base.scss';

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.Header {
  @include flexBox($display: flex, $alignItems: center, $justifyContent: flex-start, $flexWrap: wrap);
  position: relative;
  background-color: $background-color;
  height: $header-height;
  margin-bottom: toRem(1);
  box-shadow: $box-shadow-silver;
  padding: 0 $desktop-margin;

  @media (max-width: $mobile) {
    z-index: 1000;
    width: 100%;
    top: 0;
    padding: 0 $mobile-margin;

    .Header-logo-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &.Initiliazing {
    .Header-link {
      width: 140px;
      height: 0px;
      padding: 1rem 0;
      margin-left: 2rem;
      background-color: $background-color;
      animation: loading 1.5s infinite ease-in-out;
    }
  }

  * {
    font-family: $font-default;
  }

  &-center {
    justify-content: center;
  }

  &-logo {
    height: 24px;
    object-fit: contain;
    margin-top: 8px;
    margin-right: toRem(64);

    @media (max-width: $mobile) {
      height: toRem(28);
    }
  }

  &-link {
    color: $text-color;
    text-decoration: none;
    font-weight: 700;
    padding: toRem(21) 0;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 250px;

    &-active {
      border-bottom: 2px solid $primary-color;
      padding: toRem(21) 0 toRem(19); // this is to avoid the element moving when border appears
    }

    &:hover {
      color: $primary-color;
    }

    &:not(:nth-child(2)) {
      margin-left: toRem(32);
    }

    @media (max-width: $desktop-extra-small) {
      display: none;
    }
  }

  &-right-container {
    @include flexBox($display: flex, $alignItems: center, $justifyContent: center);
    margin-left: auto;
    padding: toRem(21) 0;

    a {
      display: inline-flex;
    }

    @media (max-width: $desktop-extra-small) {
      display: none;
    }
  }

  &-search-icon {
    color: $text-color;
    cursor: pointer;
    @include equal-dimensions(toRem(18));
  }

  &-settings-icon {
    margin-left: 1.5rem;
  }

  &-dropdown-menu {
    margin-left: toRem(20);

    &-button {
      color: $text-color !important;
      padding: 0 !important;
      font-size: toRem(16) !important;

      &-sign-in {
        font-size: toRem(16) !important;
        color: $black !important;
        padding: toRem(8) toRem(16);
        background-color: $primary-color !important;
        border: 0 !important;
        border-radius: 4px !important;
      }
    }

    &-item {
      color: $text-color !important;
      background-color: $background-color !important;
      border: none;

      &:hover {
        background-color: $grey200 !important;
      }

      &:last-child {
        border-top: 1px solid $dark-grey;
      }
    }
  }
}

/*
Can't access the dropdown menu container because the comp doesn't have a direct class
that affects it and can't reach it with selectors inside the parent class since it gets
rendered outside it's scope and injected at the end of the body of the HTML.
This is the only way I found.
*/
body > div:nth-child(3) > div {
  border-color: $dark-grey;
}
