@import '../../styles/base';

.SectionHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral;
  color: $text-color;
  font-weight: 700;
  font-size: toRem(24);
  line-height: toRem(28);
  padding: 2.125rem;
}
