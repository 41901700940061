@import '../../styles/base.scss';
@import '../../styles/colors';
.ForgotPasswordModal {
  &-form {
    margin: 0 2.3rem;
    @media (max-width: $mobile) {
      margin: unset;
    }
  }

  &-form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    label {
      margin-bottom: 0.5rem;
      font-size: 14px;
    }
    .TextInput-container input {
      max-width: unset;
    }
    .input-error {
      border: 1px solid $red;
    }
    .error-message {
      padding-top: 5px;
      font-size: 13px;
      color: $red;
    }
  }
  &-form-button {
    width: 100%;
  }
  &-back-button {
    text-align: center;
    a {
      color: $primary-color;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

[class^='Modal-content'] {
  max-width: 600px !important;
}
