@import '../../styles/base.scss';

.Grid {
  .Skeleton-tile {
    &-image {
      @include background($size: contain);
      padding-bottom: 56.25%;
    }
  }
}
