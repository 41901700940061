@import '../../styles/base.scss';

.hide-overflow {
  overflow: hidden;
}

.search-min-height {
  min-height: 100vh;
}

.main-content-search {
  margin: 0 !important;
  color: $text-color;

  .search-grid {
    padding-inline: 2.25rem;

    @media (max-width: $tablet) {
      padding: 0 toRem(15) 0 toRem(15);
    }

    @media (max-width: $mobile) {
      padding: 0 toRem(10) 0 toRem(10);
    }
  }

  .Hero-slider {
    .Hero-slide-overlay-content {
      padding-left: 2rem;
    }

    .Carousel-arrow-right {
      right: 3rem;
    }

    @media screen and (max-width: $mobile) {
      .Carousel-arrow-right {
        right: 1rem;
      }
    }
  }

  .Grid {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .carrousel-container {
    padding-left: 2rem;
    padding-right: 1rem;
  }
}
