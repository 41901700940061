@import '../../styles/base';

.About {
  &-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  &-title {
    font-weight: 700;
    margin-bottom: toRem(4);
  }

  &-text {
    &:not(:last-child) {
      margin-bottom: toRem(24);
    }
  }
}
