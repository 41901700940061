@import '../../styles/base.scss';

.ErrorPage {
  max-width: 100vw;
  color: $text-color;

  .ErrorPageContent {
    padding: 2rem;

    .Hero-slider {
      margin: 2rem 0;
    }
  }
}
