@import '../../styles/base.scss';

$width: 100px;

@keyframes rotate {
  100% {
    transform: rotate(359deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -140;
  }
}

.Loading-indicator {
  @include equal-dimensions($width);
  stroke: $primary-color;
  border-radius: 50%;
  margin: 4rem auto;
  min-height: unset;

  .circular {
    animation: rotate 2s linear infinite;
    height: $width;
    position: relative;
    width: $width;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}
