@import '../../styles/base.scss';

.Grid {
  margin-bottom: 4rem;
  &-header {
    @include font($fontFamily: $font-default, $size: 1.5rem, $weight: 700, $lh: 1.75rem, $color: $text-color);
    margin: 0;
  }
  &-content {
    @include grid($columns: repeat(4, 1fr), $columnGap: 1.25rem, $rowGap: 2.5rem);

    @media (max-width: $desktop-medium) {
      @include grid($columns: repeat(3, 1fr) !important, $columnGap: 1.25rem, $rowGap: 2.5rem);
    }
    @media (max-width: $tablet) {
      @include grid($columns: repeat(2, 1fr) !important, $columnGap: 1.5rem, $rowGap: 2rem);
    }
    @media (max-width: $mobile) {
      @include grid($columns: repeat(1, 1fr) !important, $columnGap: 1rem, $rowGap: 1.5rem);
    }
  }
}
