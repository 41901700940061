@import '../../../styles/base.scss';

.SettingsList {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: $text-color;

  &-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: toRem(14);
    line-height: toRem(18);
    margin-bottom: toRem(32);

    @media (max-width: $tablet) {
      &:not(:first-child) {
        margin-top: toRem(10);
      }
    }

    @media (max-width: $mobile) {
      &:not(:first-child) {
        margin-top: toRem(10);
      }
    }
  }

  &-subheader {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(14);
    line-height: toRem(18);
    margin-bottom: toRem(8);
    color: $silver;
  }

  &-item {
    font-size: toRem(18);
    line-height: toRem(24);
    text-decoration: none;
    color: $text-color;

    &:not(:last-child) {
      margin-bottom: toRem(30);
    }

    &:hover {
      color: $primary-color;
    }

    &-green {
      color: $primary-color;
    }
  }
}
