@import '../../styles/base.scss';

.Countdown {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    div:nth-child(n + 1):nth-child(-n + 3) {
      margin-right: toRem(12);

      * {
        margin-right: unset;
      }
    }

    svg path:first-child {
      stroke: $white !important;
    }
  }

  &-value {
    color: $white;
    font-weight: 600;
    font-size: toRem(44);
    line-height: toRem(53);
    text-align: center;
    text-transform: uppercase;

    @media (max-width: $desktop-small) {
      font-size: toRem(32);
      line-height: toRem(38);
    }

    @media (max-width: $tablet-portrait) {
      font-size: toRem(28);
      line-height: toRem(34);
    }

    @media (max-width: $mobile) {
      font-size: toRem(24);
      line-height: toRem(28);
    }
  }

  &-text {
    color: $white;
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(19);
    text-align: center;
    text-transform: uppercase;

    @media (max-width: $tablet) {
      font-size: toRem(14);
      line-height: toRem(17);
    }

    @media (max-width: $tablet-portrait) {
      font-size: toRem(12);
      line-height: toRem(15);
    }

    @media (max-width: $mobile) {
      font-size: toRem(12);
      line-height: toRem(15);
    }
  }
}
