@import '../../styles/colors';

.GenericModal {
  [class^='Modal-header'] {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  [class^='Modal-actions'] {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $background-color;
  }
  [class^='Modal-close'] {
    color: $black;
    font-size: 30px;
  }
  [class*='Modal-body'] {
    background-color: $background-color;
    overflow: auto;
  }

  .generic-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 24px;
      margin: 1rem 0 0 0;
    }
    h5 {
      font-family: Helvetica Neue;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 1rem;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
