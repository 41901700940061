@import '../../styles/base';

.Service-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $primary-color;
  padding: toRem(10) toRem(17);
  border-bottom: 1px solid $white;

  &:last-child {
    border-bottom: 0;
  }

  &-text,
  &-text a {
    font-weight: 500;
    font-size: toRem(15);
    color: $text-color;
    margin: 0;
    flex: 8;
  }

  &-icon {
    font-size: toRem(20);
    color: $text-color;
    cursor: pointer;

    &-container {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
}
