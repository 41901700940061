@import '../../styles/base';

.MaintenanceMode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: toRem(32);
  color: $text-color;
  height: 100%;

  &-title {
    font-weight: 600;
    font-size: toRem(42);
  }
}
