@use 'sass:math';
@import './_colors';
@import './mixins';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
// Font
$font-default: 'Helvetica Neue', Arial, sans-serif;
$font-helvetica: 'Helvetica Neue', Arial, sans-serif;

$base-font-size: 16px; // Set your base font size in pixels

@function toRem($number) {
  // Convert value to px and calculate the rem value by dividing it by the base font size
  @return math.div(($number * 1px), $base-font-size) * 1rem;
}

$box-shadow-silver: 0 0.0625rem 0 rgba($silver, 0.25);
$border-radius: 4px;
$header-height: 5rem;
$footer-height: 5rem; // keeping this as the header in case in the future it changes
$desktop-max-width: 1600px;
$desktop-medium: 1440px;
$desktop-small: 1280px;
$desktop-extra-small: 1150px;
$tablet: 1024px;
$tablet-portrait: 768px;
$mobile: 640px;
$mobile-small: 480px;
$mobile-extra-small: 350px;

$desktop-margin: 2.125rem;
$mobile-margin: 1.25rem;

html,
body,
#root {
  border: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  box-sizing: border-box;
  height: fit-content;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline-color: $primary-color !important;
}

body {
  font-size: $base-font-size;
  background-color: $background-color;
  margin: 0;
  font-family: $font-default;

  &.disable-scroll {
    overflow: hidden;

    @media (max-width: $desktop-extra-small) {
      .Service-message {
        display: none;
      }
    }

    @media (max-width: $mobile) {
      .Header {
        position: fixed;
      }

      .app-layout {
        padding-top: $header-height;
      }
    }
  }
}

.app-layout {
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: auto;
}

.app-body {
  position: relative;
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 10rem);
  overflow-x: hidden;
  > div {
    height: 100%;
  }
  > .Loading-indicator {
    min-height: 100vh;
  }

  @media only screen and (max-width: $mobile-extra-small) {
    .CookieConsentNotification {
      height: 8rem !important;
    }
  }
}

.main-content {
  margin: 0 $desktop-margin;
  @media (max-width: $mobile) {
    margin: 0 $mobile-margin;
  }
}
