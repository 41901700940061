@import '../../styles/base';
@import '../../styles/colors';

.SectionTitle {
  .search-title {
    display: flex;
    justify-content: center;
    margin: toRem(20);

    h2 {
      font-family: $font-helvetica;
      color: $text-color;
    }
  }
}
