@import '../../../../styles/base.scss';

.Hero {
  &-slider {
    color: $white;
    width: 100vw;
    margin-left: -2.125rem;

    @media (max-width: $mobile) {
      margin-left: -1.25rem;
    }

    .slick-list {
      cursor: pointer;
    }

    .slick-track {
      margin: 0;
    }

    .Carousel {
      &-arrow {
        z-index: 9;
        top: 45%;
        @include equal-dimensions(28px);

        img {
          width: 100%;
          height: 100%;
        }

        @media (max-width: $desktop-small) {
          top: 42%;
        }

        @media (max-width: $tablet) {
          top: 45%;
          @include equal-dimensions(28px);
        }

        @media (max-width: $mobile) {
          top: 54%;
          @include equal-dimensions(33px);

          &.Carousel-arrow-right {
            right: 26px;
          }
        }
      }

      &-arrow::before {
        color: $black-variation;
        font-size: 33px;
        display: none;
      }

      &-arrow-disabled {
        display: none !important;
      }

      &-arrow-left {
        left: 10px;
      }

      &-arrow-right {
        right: 35px;
      }
    }

    &-dots {
      padding: 0;
      text-align: center;

      li {
        list-style: none;
        cursor: pointer;
        display: inline-block;
        margin: 0 7px;
        padding: 0;

        &.slick-active button {
          background-color: $dark-grey;
        }

        button {
          background: $grey;
          color: transparent;
          cursor: pointer;
          border: 2px solid $white;
          height: 15px;
          width: 15px;
          border-radius: 7.5px;

          &:hover,
          &:focus {
            background-color: $text-color;
          }
        }
      }
    }

    .Hero-slide {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 78.12%);
        z-index: 1;
      }

      &-overlay-content {
        display: block;
      }
    }
  }

  &-slide {
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    height: 500px;
    padding: $desktop-margin;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index: 2;

    &-overlay-content {
      display: none;
      z-index: 2;
      width: 100%;

      &-text {
        max-width: 40%;

        @media (max-width: $tablet) {
          max-width: 60%;
        }

        @media (max-width: $mobile) {
          max-width: 100%;
        }
      }

      @media (max-width: $mobile) {
        padding-right: 2rem;
      }
    }

    @media (max-width: $desktop-small) {
      height: 400px;
    }

    @media (max-width: $mobile) {
      padding: 2rem 1.4rem;
    }
  }

  &-text {
    &-eyebrow {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 22px;
      margin: 0;
      @include line-clamp(1);

      @media (max-width: $mobile) {
        font-weight: 500;
      }
    }

    &-title {
      font-size: 3rem;
      font-weight: 700;
      line-height: 64px;
      margin: 1rem 0;
      @include line-clamp(2);

      @media (max-width: $tablet) {
        font-size: 2rem;
        font-weight: 700;
        line-height: 36px;
      }
    }

    &-description {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 22px;
      margin: 0 0 2rem;
      @include line-clamp(3);

      @media (max-width: $tablet) {
        margin-bottom: 1rem;
      }

      @media (max-width: $mobile) {
        font-size: 1rem;
        line-height: 20px;
      }
    }
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    button:nth-child(1) {
      margin: 1rem 1.3rem 0 0 !important;
    }

    button:nth-child(2) {
      margin: 1rem 0 0 !important;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button:nth-child(2) {
        margin: 1rem 0 0 !important;
      }
    }
  }
}
