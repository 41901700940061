@import '../../styles/base.scss';

.Footer {
  @include flexBox($display: flex, $alignItems: center, $justifyContent: flex-start, $flexWrap: wrap);
  position: relative;
  background-color: $background-color;
  height: $footer-height;
  padding: 1rem $desktop-margin;
  border-top: 1px solid darken($background-color, 10%);

  @media (max-width: $desktop-small) {
    padding: 1rem 1.25rem;
  }

  @media (max-width: $tablet) {
    @include flexBox($flexDirection: column, $justifyContent: flex-start, $alignItems: center);
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  * {
    font-family: $font-default;
  }

  &-logo {
    height: 24px;
    object-fit: contain;
    margin-right: 4rem;

    @media (max-width: $desktop-small) {
      margin-right: 1rem;
    }

    @media (max-width: $tablet) {
      margin-right: 0;
    }
  }

  &-link {
    color: $text-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }

    &:not(:nth-child(2)) {
      margin-left: 2rem;
    }

    @media (max-width: $desktop-small) {
      &:not(:nth-child(2)) {
        margin-left: 1rem;
      }
    }

    @media (max-width: $tablet) {
      margin-top: 0.5rem;

      &:not(:nth-child(2)) {
        margin-left: 0;
      }
    }
  }

  &-right-text {
    margin-left: auto;
    color: $text-color;

    @media (max-width: $tablet) {
      margin-left: 0;
      margin-top: 1rem;
      text-align: center;
    }
  }
}
