@import '../../styles/base.scss';

.Custom-Page {
  width: 100%;
  max-width: $desktop-small;
  margin: 0 auto;

  .ContainerWithTitle-container {
    justify-content: center;
    padding: toRem(44);
    overflow-y: auto;
  }

  a {
    color: $text-color;
  }
}
