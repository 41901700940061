@import '../../styles/base.scss';

/* 16:9 aspect ratio (9 / 16 * 100) */
$ratio-16-9: 56.25%;

.Tile {
  position: relative;
  display: block;
  font-family: $font-default;
  text-decoration: none;

  @media (max-width: $mobile-extra-small) {
    min-width: toRem(250);
  }

  .Tile-image-container {
    border-radius: 0.25rem;
    overflow: hidden;

    .Tile-image {
      @include background($size: cover);
      position: relative;
      background-color: $grey40;
      padding-top: $ratio-16-9;

      &-overlay-gradient {
        @include equal-dimensions(100%);
        @include transition(visibility 0s, opacity 0.5s linear);
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba($black, 0) 48.28%, rgba($black, 0.65) 67.82%, $black 100%);
        visibility: hidden;
        opacity: 0;
      }

      &:after {
        @include background($imagePath: '../../assets/images/icons/play.svg', $size: cover);
        @include absolute-center();
        @include equal-dimensions(3.375rem);
        @include transition(visibility 0s, opacity 0.5s linear);
        content: '';
        background-color: $white;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
      }

      &:hover {
        @include transition(all 0.5s);
        transform: scale(1.02);

        .Tile-image-overlay-gradient {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .Tile-featured-label {
      @include font($size: 0.75rem, $weight: 700, $lh: 0.9375rem, $color: $black);
      position: absolute;
      top: 0.75rem;
      right: 1.375rem;
      background: $primary-color;
      border: 1px solid $grey;
      border-radius: 1.875rem;
      padding: 0.375rem 0.75rem;
      margin: 0;
    }

    .Tile-progress {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 2;

      &-bar {
        height: 0.25rem;
        background-color: $primary-color;
      }
    }

    .Tile-favorite {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      z-index: 2;
      transform: scale(1);
      display: block;

      &.loading {
        opacity: 0.5;
      }

      &:hover {
        transform: scale(1.2);
        transition: transform 0.2s ease;
      }
      @media (max-width: $tablet) {
        img {
          @include equal-dimensions(35px);
        }
      }

      @media (max-width: $mobile) {
        img {
          @include equal-dimensions(44px);
        }
      }
    }
  }

  &-info {
    &-title {
      @include font(
        $fontFamily: $font-default,
        $size: 1.125rem,
        $weight: 600,
        $lh: 1.375rem,
        $color: $text-color
      );
      @include line-clamp(3);
      margin: 0.75rem 0 0.375rem;

      @media (max-width: $tablet) {
        @include line-clamp(2);
        font-weight: 600;
      }
    }

    &-metadata {
      span {
        @include font($size: 1rem, $weight: 400, $lh: 1.25rem, $color: $dark-grey);
        opacity: 0.66;

        &:after {
          position: relative;
          content: '|';
          margin: 0 0.25rem;
        }

        &:last-child:after {
          display: none;
        }
      }
    }
  }

  &.ad,
  &.episode,
  &.movie {
    .Tile-image:hover:after {
      visibility: visible;
      opacity: 1;
    }
  }
}
