@import '../../styles/base.scss';

.carrousel-container {
  padding-top: 1rem;
}

.Carousel {
  &-header {
    @include font($fontFamily: $font-default, $size: 1.5rem, $weight: 700, $lh: 1.75rem, $color: $text-color);
    margin: 0;

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 1.375rem;
    }
  }

  &-view-more {
    color: $text-color;
    font-weight: 700;
    text-decoration: none;
    margin-right: 1rem;
    min-width: min-content;
    text-align: right;
    text-decoration: underline $primary-color 2px;

    &:hover {
      color: $primary-color;
    }
  }

  &-slider {
    color: $text-color;
    @media (max-width: $mobile) {
      margin: 0;
    }

    .slick-list {
      cursor: pointer;
    }

    .slick-track {
      margin: 0;
    }

    .slick-next {
      width: 26px;

      img {
        width: 34px;
      }
    }
  }

  &-arrow {
    z-index: 9;
    top: 32%;

    @media (max-width: $desktop-small) {
      top: 28%;
    }

    @media (max-width: $tablet) {
      top: 25%;

      img {
        @include equal-dimensions(28px);
      }
    }

    @media (max-width: $mobile) {
      top: 32%;
      img {
        @include equal-dimensions(33px);
      }
    }
  }

  &-arrow::before {
    color: $black-variation;
    font-size: 33px;
    display: none;
  }

  &-arrow-disabled {
    display: none !important;
  }

  &-arrow-left {
    left: 10px;
  }

  &-arrow-right {
    right: 35px;
  }
}
