@import '../../styles/base.scss';

.Filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: toRem(32);
  padding: 0 toRem(30) 0 toRem(30);

  @media (max-width: $mobile) {
    padding: 0 toRem(20) 0 toRem(20);
  }

  &-title-container {
    display: flex !important;
    align-items: center;
    width: 100%;
  }

  &-chevron {
    margin-left: auto;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &-clear-all,
  &-clear-all-mobile {
    font-size: toRem(14);
    font-weight: 700;
    line-height: toRem(18);
    color: $text-color;
    cursor: pointer;
    margin: 0 0 toRem(14);

    &:hover {
      color: $grey;
    }
  }

  &-clear-all {
    display: block;
    @media (max-width: $mobile) {
      display: none;
    }
  }

  &-clear-all-mobile {
    display: none;
    @media (max-width: $mobile) {
      display: block;
    }
  }

  &-accordion {
    position: relative;
    width: 100%;
    background-color: $background-color;
    color: $text-color;
    border: 1px solid $white;
    display: none;

    div {
      background-color: $background-color;

      div {
        margin: 0;
      }
    }

    svg {
      color: $text-color;
    }

    @media (max-width: $mobile) {
      display: block;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: $mobile) {
      display: none;
    }

    &-mobile {
      @media (max-width: $mobile) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }
  }
}
