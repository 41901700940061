@import '../../styles/base.scss';

.ReactPlayer {
  height: 100%;
  width: 100%;

  .video-js {
    font-family: $font-default;

    .bc-live-cta-overlay .bc-live-cta-container .bc-live-cta,
    .vjs-quality-menu-button,
    .vjs-control-text,
    .vjs-menu .vjs-menu-item,
    .vjs-menu-item-text {
      font-family: $font-default;
    }

    .vjs-modal-dialog {
      &.vjs-text-track-settings {
        height: 100%;
      }
    }

    .vjs-modal-dialog-content {
      form {
        width: 100%;

        .vjs-social-description {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.hide-controls {
  .vjs-control-bar {
    display: none;
  }
}
