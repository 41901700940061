@import '../../styles/base.scss';

.AuthCallback {
  &-container {
    margin-top: 4rem;
    color: $text-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-countdown-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1,
    h4 {
      margin: 0;
    }
  }
}
