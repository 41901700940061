@import '../../styles/base.scss';

.TextInput {
  @include font($fontFamily: $font-default, $size: 1rem, $weight: 400, $lh: 1.25rem, $color: $grey40);
  background-color: $background-color;
  max-width: 27.875rem;
  width: inherit;
  height: 3.125rem;
  padding: 1rem 0.625rem;
  border: 0.0625rem solid $grey40;
  border-radius: $border-radius;
  outline: none;
  position: relative;
  &-container {
    position: relative;
    width: 100%;
  }
  &-password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../assets/images/icons/view-pass.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}
