@import '../../styles/base.scss';

.SeriesDetail {
  &-desktop-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-height: toRem(382);
    height: 100%;
    box-sizing: content-box;
    color: $text-color;
    margin-bottom: toRem(32);

    @media (max-width: $tablet) {
      display: none;
    }

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      max-width: toRem(500);
      height: toRem(382);
    }

    &-right {
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      max-height: toRem(382);
      max-width: toRem(677);
      height: 100%;
      width: 100%;
    }
  }

  &-mobile-container {
    display: none;
    color: $text-color;
    margin: 0 -#{$desktop-margin} 2rem;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    height: 382px;
    padding: $desktop-margin;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: $desktop-small) {
      height: 400px;
    }

    @media (max-width: $tablet) {
      display: flex;
    }

    @media (max-width: $mobile) {
      padding: 2rem 1.4rem;
    }
  }

  &-text {
    &-eyebrow {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 22px;
      margin: 0;

      @media (max-width: $mobile) {
        font-weight: 500;
      }
    }

    &-title {
      font-size: 3rem;
      font-weight: 700;
      line-height: 62px;
      margin: 1rem 0;
      max-width: 70%;

      @media (max-width: $mobile) {
        font-size: 2rem;
        font-weight: 700;
        line-height: 34px;
        max-width: 90%;
      }
    }

    &-description {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 22px;
      margin: 0 0 2rem;

      @media (max-width: $mobile) {
        font-size: 1rem;
        line-height: 20px;
      }
    }
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: auto;

    button:nth-child(2) {
      margin: 0 0 0 1.3rem !important;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button:nth-child(2) {
        margin: 1rem 0 0 !important;
      }
    }
  }

  &-seasons-container {
    margin-bottom: 1rem;
  }

  &-dropdown-title {
    color: $text-color;
    font-family: $font-default;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
  }
}
