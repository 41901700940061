@import '../../styles/base.scss';

.Settings {
  &-desktop {
    display: flex;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &-tablet {
    display: none;

    @media (max-width: $tablet) {
      display: flex;
    }

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @media (max-width: $mobile) {
      display: flex;
    }
  }

  &-center {
    @media (min-width: $mobile) {
      justify-content: center;
    }
  }
}
